<template>
  <div class="ride-hailing-container" :class="!showBackButton && 'vehicles-container'">
    <button
      v-if="showBackButton"
      class="back-btn"
      @click="$router.go(-1)"
    >
      <q-icon name="fas fa-arrow-left" color="primary" size="20px" />
    </button>
    <route-map
      :class="['map', shortMap ? 'short' : '', changesMade && vehicleGroups ? 'search-changed' : '']"
      @originClick="focusOrigin"
      @destinationClick="focusDestination"
    />
    <ride-form ref="form" :form-mode="shortMap ? 'results' : ''" />

    <transition :name="transitionName" appear mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from 'store'
import routeMap from './route-map'
import rideForm from './form'
export default {
  components: { routeMap, rideForm },
  data () {
    return {
      transitionName: null
    }
  },
  computed: {
    shortMap () {
      return this.$route.path === '/on-demand/ride-hailing/results' || this.$route.path === '/on-demand/ride-hailing/vehicle'
    },
    showBackButton () {
      if (this.$q.platform.is.desktop) return false
      if (this.$route.meta.layout?.components?.appbar === false) return true
      return false
    },
    ...mapGetters({
      changesMade: 'rideHailing/changesMade',
      vehicleGroups: 'rideHailing/vehicleGroups'
    })
  },
  watch: {
    '$route' (to, from) {
      const toT = to.path.split('/')[to.path.split('/').length - 1]
      const fromT = from.path.split('/')[from.path.split('/').length - 1]
      this.transitionName = `from-${fromT}-to-${toT}`
    }
  },
  methods: {
    focusOrigin () {
      this.$refs.form.focusOrigin()
    },
    focusDestination () {
      this.$refs.form.focusDestination()
    }
  },
  beforeRouteEnter (to, from, next) {
    if (!from.path.includes('ride-hailing') && !from.path.includes('smart')) {
      store.dispatch('rideHailing/reset')
    }
    next()
  }
}
</script>

<style lang="stylus">

@keyframes fadeOut {
  0%   { opacity: 1 }
  100% { opacity: 0 }
}

@keyframes slideOut {
  0% { bottom: 0 }
  100% { bottom: -300px }
}

@keyframes slideIn {
  0% { bottom: -300px }
  100% { bottom: 0 }
}

.to-results-enter-active, .to-results-leave-active {
  transition: opacity 1s;
}
.to-results-enter, .to-results-leave-to {
  opacity: 0;
}

.to-results-enter-to {
  opacity: 1;
}

.from-results-to-vehicle-leave-active, .from-vehicle-to-results-leave-active
  transition height .3s, opacity .3s, bottom .3s
  & > *
    transition opacity .3s!important

.from-results-to-vehicle-leave-to
  height: calc(100vh - 64px)!important;
  bottom: 0;
  position: absolute!important;
  background white

  & > *
    opacity 0

  @media (min-width 768px)
    height 300px!important
    animation slideOut .3s forwards!important

    & > *
      opacity 1

.from-results-to-vehicle-enter-active
  @media (min-width 768px)
    animation slideIn .3s

.from-vehicle-to-results-leave-to
  height: 300px!important
  & > *
    animation: fadeOut .3s forwards!important

  @media (min-width 768px)
    height auto
    animation: slideOut .3s forwards!important
    & > *
      animation none

.ride-hailing-container
  position relative
  height 100vh
  overflow: hidden
  &.vehicles-container
    height calc(100vh - 64px)
.map.short
  @media (max-width 768px)
    height 60vh

.back-btn
  position absolute
  left 5px
  top 10px
  z-index 2
  border-radius 50%
  margin-right 10px
  width 36px
  height 36px
  background rgba(255, 255, 255, 1)
  border 1px solid rgba(0, 0, 0, 0.2)
</style>
