<template>
  <div
    :class="changesMade && vehicleGroups ? 'search-changed' : ''"
    class="groups shadow-up-2 bg-white"
  >
    <div v-if="!vehicleGroups" class="loading">
      <q-spinner-arrow v-if="partner.spinnerType === 'arrow'" :color="partner.hex" size="64" class="loader" />
      <q-spinner-geo v-else size="64" class="loader" />
      <p>{{ $t('loading.searching.taxi') }}</p>
    </div>

    <div v-if="requestCompletedWithNoResults" style="display: grid; width: 100%;">
      <h5 class="empty">
        {{ $t('no_taxis_found') }}
      </h5>
    </div>

    <div v-else-if="!changesMade" class="full-width">
      <q-tabs
        v-model="tab"
        align="justify"
        class="bg-transparent full-width"
      >
        <q-tab
          v-for="v in vehicleGroups"
          :key="v.category_label"
          :name="v.category_label"
          class="taxi-tab"
        >
          <taxi-topper
            :text="v.category_label"
          />
        </q-tab>
      </q-tabs>

      <q-tab-panels
        v-model="tab"
        class="full-width"
      >
        <q-tab-panel
          v-for="v in vehicleGroups"
          :key="v.category_label"
          :name="v.category_label"
          style="padding: 0;"
        >
          <vehicle-type-list
            :key="v.name"
            :vehicle-group="v"
          />
        </q-tab-panel>
      </q-tab-panels>
    </div>

    <q-btn v-else-if="changesMade && vehicleGroups" color="primary" size="lg" class="btn-search" label="Find your taxi" @click="getResults" />
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vehicleTypeList from './vehicle-type-list'
import taxiTopper from './taxi-topper.vue'
import QSpinnerArrow from 'components/MSpinner/Arrow'
import QSpinnerGeo from 'components/MSpinner/Geo'
import store from 'store'
import i18n from 'i18n'
import loading from 'utils/loading'
import { handleErrors } from 'utils/utils'

export default {
  components: { vehicleTypeList, taxiTopper, QSpinnerArrow, QSpinnerGeo },
  data () {
    return {
      slide: null,
      tab: 'Taxi'
    }
  },
  computed: {
    ...mapGetters({
      vehicleGroups: 'rideHailing/vehicleGroups',
      changesMade: 'rideHailing/changesMade',
      partner: 'partner'
    }),
    requestCompletedWithNoResults () {
      return !this.changesMade && this.vehicleGroups && !this.vehicleGroups.length
    }
  },
  methods: {
    selectVehicle (vehicle) {
      this.$store.dispatch('rideHailing/setValue', { prop: 'selectedVehicle', payload: vehicle })
    },
    getResults () {
      this.$store.dispatch('rideHailing/search')
    }
  },
  async beforeRouteEnter (to, from, next) {
    const partner = store.getters.partner

    loading.start({
      message: i18n.t('loading.searching.taxi'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      await store.dispatch('rideHailing/search')
      next(() => loading.stop())
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  }
}
</script>

<style lang="stylus" scoped>

@keyframes slideUp {
  100% { bottom: 0}
}

.groups
  position fixed
  z-index 100
  background: white;
  width: 100%;
  height: 300px;
  transition height .3s

  @media (min-width: 768px)
    position absolute
    width 624px
    left: 50%;
    bottom: -300px
    transform: translateX(-50%);
    animation slideUp .3s forwards

.groups.search-changed
  height 72px

.loading
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

.loading p
  margin-top 16px
  text-align center

.groups
  display flex

.carousel
  width 100%

.btn-search
  margin 12px
  width 100%
.empty
  width: 70%
  font-weight: 300
  margin auto
  text-align center

.loader
  width: 100px
  height: 100px
  margin: auto
</style>

<style lang="stylus">
.groups .q-carousel-left-arrow, .groups .q-carousel-right-arrow
  background white
  top: 25px;

.taxi-tab
  width 20vw
  padding 0
</style>
