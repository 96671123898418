<template>
  <div class="form-container">
    <q-card v-if="!hideForm" :class="[formMode, mode]" class="ride-form bg-white">
      <div class="content" :class="mode === 'short' && !formMode ? 'absolute-center full-width q-px-md' : ''">
        <transition name="fade" mode="out-in">
          <div class="form">
            <m-location-search
              ref="pickUp"
              :value="origin"
              :autofocus="mode === 'full'"
              :label="$t('pick_up_location')"
              no-shadow
              keep-open
              @selected="setOrigin"
              @open="pickupOpen"
            />
            <m-location-search
              ref="dropOff"
              :value="destination"
              :label="$t('going_to')"
              no-shadow
              keep-open
              @selected="setDestination"
              @open="dropoffOpen"
            />
            <leave-now
              :departing="timeString"
              :show-transport-prefs="false"
              :disabled="searchDisabled"
              @depart="val => setTime(val)"
              @submit="getResults"
            />
          </div>
        </transition>
      </div>
    </q-card>
  </div>
</template>

<script>
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { MLocationSearch, LeaveNow } from 'components/'
import date from 'utils/date-time'

export default {
  components: { MLocationSearch, LeaveNow },
  mixins: [ authentication ],
  props: ['formMode'],
  data () {
    return {
      minDate: date.roundMinutesUp(date.addToDate(date.newDate(), { minutes: 5 }), 5),
      modeSet: null
    }
  },
  computed: {
    ...mapGetters({
      userLookup: 'userLookup',
      origin: 'rideHailing/origin',
      destination: 'rideHailing/destination',
      time: 'rideHailing/time',
      selectedVehicle: 'rideHailing/selectedVehicle',
      changesMade: 'rideHailing/changesMade',
      traveller: 'rideHailing/traveller',
      requester: 'rideHailing/requester'
    }),
    mode: {
      get () {
        if (this.modeSet) {
          return this.modeSet
        } else if (this.formMode && !this.origin && !this.destination) {
          return this.formMode
        } else if (this.origin && this.destination) {
          return 'short'
        } else {
          return ''
        }
      },
      set (val) {
        this.modeSet = val
      }
    },
    people: {
      get () {
        return {
          traveller: this.traveller,
          requester: this.requester
        }
      },
      set (val) {
        this.$store.dispatch('rideHailing/setTraveller', val.traveller)
        this.$store.dispatch('rideHailing/setRequester', val.requester)
        if (this.origin && this.destination) {
          this.mode = 'short'
        }
      }
    },
    searchDisabled () {
      return !this.origin || !this.destination || !this.time
    },
    hideForm () {
      return ['ondemand-ride-hailing-vehicle', 'ondemand-ride-hailing-results'].includes(this.$route.name)
    },
    timeString () {
      return date.toCivilDateTime(this.time)
    }
  },
  watch: {
    $route (val, old) {
      if (val.path === '/on-demand/ride-hailing/vehicle') {
        this.$refs.dropOff.close()
        this.$refs.pickUp.close()
        this.mode = 'short'
      }
      if (val.path === '/on-demand/ride-hailing/' && old.path === '/on-demand/ride-hailing/results') {
        this.mode = 'full'
      }
    }
  },
  created () {
    if (this.$route?.params?.origin) {
      const { label, latitude, longitude } = this.$route.params.origin
      const location = {
        label,
        latlng: { lat: Number(latitude), lng: Number(longitude) },
        value: `${latitude},${longitude}`
      }
      this.$store.commit('rideHailing/setValue', { prop: 'origin', payload: location })
    }
    if (!this.time) {
      this.$store.dispatch('rideHailing/setValue', {
        prop: 'time',
        payload: this.minDate
      })
    }

    this.$store.dispatch('rideHailing/setRequester', this.userLookup)
  },
  mounted () {
    if (this.origin && !this.destination) {
      this.$refs.dropOff.$refs.input.focus()
    } else if (!this.origin) {
      this.$refs.pickUp.$refs.input.focus()
    }
  },
  methods: {
    async setOrigin (val) {
      await this.$store.dispatch('rideHailing/setOrigin', val)
      if (!this.origin) {
        this.$refs.pickUp.$refs.input.focus()
      } else if (!this.destination) {
        this.$refs.dropOff.$refs.input.focus()
      } else if (this.$q.platform.is.desktop) {
        this.mode = 'short'
      }
    },
    async setDestination (val) {
      await this.$store.dispatch('rideHailing/setDestination', val)
      if (!this.destination) {
        this.$refs.dropOff.$refs.input.focus()
      } else if (!this.origin) {
        this.$refs.pickUp.$refs.input.focus()
      } else {
        this.$store.dispatch('rideHailing/setTraveller', this.userLookup)
        if (this.$q.platform.is.desktop) this.mode = 'short'
      }
    },
    setTime (val) {
      this.$store.dispatch('rideHailing/setValue', { prop: 'time', payload: val })
    },
    clearOrigin () {
      this.$store.dispatch('rideHailing/setValue', { prop: 'origin', payload: null })
    },
    clearDestination () {
      this.$store.dispatch('rideHailing/setValue', { prop: 'destination', payload: null })
    },
    getResults () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.mode = 'results'
        this.$router.push({ name: 'ondemand-ride-hailing-results' })
      } else {
        this.$q.notify({ color: 'negative', icon: 'clear', message: this.$t('taxi.validation.form') })
      }
    },
    focusOrigin () {
      this.$refs.pickUp.$refs.input.focus()
    },
    focusDestination () {
      this.$refs.dropOff.$refs.input.focus()
    },
    pickupOpen () {
      this.mode = 'full'
      this.$refs.dropOff.close()
      if (this.$route.path === '/on-demand/ride-hailing/vehicle') {
        this.$router.push('/on-demand/ride-hailing/results')
      }
    },
    dropoffOpen () {
      this.mode = 'full'
      this.$refs.pickUp.close()
      if (this.$route.path === '/on-demand/ride-hailing/vehicle') {
        this.$router.push('/on-demand/ride-hailing/results')
      }
    }
  },
  validations: {
    origin: { required },
    destination: { required },
    time: { required }
  }
}
</script>

<style lang="stylus" scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.ride-form
  margin 0
  width 100vw
  position: absolute;
  overflow-y scroll
  transition .3s
  padding 0 16px
  z-index 1

  @media (min-width: 768px)
    width 20vw
    max-width 420px
    height 56px
    top 70%
    right: 50%;
    transform: translate(50%, -50%);

.ride-form.full
  @media (min-width: 768px)
    height: 60vh;
    top 50%
    right 50%
    width 33vw
    transform: translate(50%, -50%);

.ride-form.short
  @media (min-width: 768px)
    transform: translate(0%, -0%);
    min-height 320px
    top 16px
    right 16px
    width 18vw

.ride-form.results
  transform: translateY(-110vh);
  min-height 178px
  height auto
  @media (min-width: 768px)
    top: 16px;
    transform: translate(0,0);
    right: 16px;
    width: 300px;

.ride-form.results.full
  transform: translate(0,0);
  height auto

h5
  width 100%
  position absolute
  font-size 16px
  padding 10px
  cursor pointer

  i
    font-size 36px

  span
    vertical-align bottom

.time
  padding 10px
  z-index 100
  width: 100vw;
  height: 72px;
  background: white;
  margin: 0;
  position: fixed;
  transition bottom .3s, height .3s

  @media (min-width: 768px)
    position: absolute;
    width 624px
    left: 50%
    transform: translateX(-50%)

.time.short
  bottom 0
.time.short.results
  bottom -150px

.time-visible.full
  bottom -135px

.btn-search
  background var(--q-color-primary, #218AE5)
  color white
  width 100%
  transition width .3s

.inline-btn-search
  margin-bottom 16px
  animation-duration .3s!important

.btn-time
  background var(--q-color-primary, #218AE5)
  color white
  width: 100%;
</style>
