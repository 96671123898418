<template>
  <GmapMap
    ref="map"
    :center="center"
    :zoom="zoom"
    :options="mapConfig"
    class="route-map"
    map-type-id="roadmap"
  >
    <GmapMarker
      v-if="origin"
      ref="originMarker"
      :key="origin.label"
      :position="origin.latlng"
      :icon="{
        url: require(`assets/markers/green.png`)
      }"
      class="marker"
    />

    <GmapMarker
      v-if="destination"
      ref="destinationMarker"
      :key="destination.label"
      :position="destination.latlng"
      :icon="{
        url: require(`assets/markers/red.png`)
      }"
      class="marker"
    />

    <GmapPolyline
      v-if="routeInfo"
      ref="line"
      :key="routeInfo.distance.kilometres"
      :options="{ strokeColor: '#218AE5', strokeWeight: 4 }"
    />
  </GmapMap>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import mapConfig from './map-config'
import { gmapApi } from 'gmap-vue'

let SnazzyInfoWindow

export default {
  data () {
    return {
      mapConfig,
      center: {
        lat: 54.552956,
        lng: -2.5708507
      },
      originInfo: null,
      destinationInfo: null
    }
  },
  computed: {
    google: gmapApi,
    ...mapGetters({
      origin: 'rideHailing/origin',
      destination: 'rideHailing/destination',
      routeInfo: 'rideHailing/routeInfo',
      changesMade: 'rideHailing/changesMade'
    }),
    zoom () {
      return this.$q.platform.is.mobile
        ? 10
        : 7
    },
    originContent () {
      return this.origin ? `
        <div style="display: flex;">
          <small>Start</small><br>
          <span class="window-text">
            ${this.origin.label.split(',')[0]}
          </span>
          <i class="material-icons window-chevron">keyboard_arrow_right</i>
        </div>
      ` : ''
    },
    destinationContent () {
      return this.destination ? `
        <div style="display: flex;">
          <small>End</small><br>
          <span class="window-text">
            ${this.destination.label.split(',')[0]}
          </span>
          <i class="material-icons window-chevron">keyboard_arrow_right</i>
        </div>
      ` : ''
    }
  },
  watch: {
    originContent (val) {
      this.originInfo && this.originInfo.setContent(val)
    },
    destinationContent (val) {
      this.destinationInfo && this.destinationInfo.setContent(val)
    },
    origin: {
      immediate: true,
      handler (val) {
        if (!val) return
        if (this.originInfo) this.originInfo.destroy()
        Vue.nextTick()
          .then(() => this.destination && this.updateRouteInfo())
          .then(() => this.createInfoWindow(this.$refs.originMarker, this.originContent, () => this.$emit('originClick')))
          .then(info => { this.originInfo = info })
          .then(this.setBounds)
      }
    },
    destination: {
      immediate: true,
      handler (val) {
        if (!val) return
        if (this.destinationInfo) this.destinationInfo.destroy()
        Vue.nextTick()
          .then(this.updateRouteInfo)
          .then(() => this.createInfoWindow(this.$refs.destinationMarker, this.destinationContent, () => this.$emit('destinationClick')))
          .then(info => { this.destinationInfo = info })
          .then(this.setBounds)
      }
    },
    routeInfo (val, old) {
      if (old && (val.distance.miles === old.distance.miles)) return
      const vm = this
      const polyline = val.points
      Vue.nextTick()
        .then(() => vm.$refs.line.$polylinePromise)
        .then(line => {
          let step = 0
          let point = 0
          let numSteps = 1
          let timePerStep = 1

          line.setPath([])

          if (polyline.length < 2) return

          let departure = new this.google.maps.LatLng(polyline[step].lat, polyline[step].lng)
          let arrival = new this.google.maps.LatLng(polyline[step + 1].lat, polyline[step + 1].lng)

          function drawLine () {
            step += 1
            if (step > numSteps) {
              clearInterval(interval)
              if (point + 1 < polyline.length) {
                step = 0
                departure = new this.google.maps.LatLng(polyline[point].lat, polyline[point].lng)
                arrival = new this.google.maps.LatLng(polyline[point + 1].lat, polyline[point + 1].lng)
                point += 1
                interval = setInterval(drawLine, timePerStep)
              }
            } else {
              let areWeThereYet = this.google.maps.geometry.spherical.interpolate(departure, arrival, step / numSteps)

              line.setPath([...line.getPath().getArray(), departure, areWeThereYet])
            }
          }

          let interval = setInterval(drawLine, timePerStep)
        })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.map.$mapPromise.then(() => {
        import('snazzy-info-window')
          .then(lib => {
            SnazzyInfoWindow = lib.default
          })
          .then(() => {
            if (this.origin) return this.createInfoWindow(this.$refs.originMarker, this.originContent, () => this.$emit('originClick'))
          })
          .then(info => { this.originInfo = info })
      })
    })
  },
  activated () {
    this.$refs.map.$mapPromise.then((map) => {
      import('snazzy-info-window')
        .then(lib => {
          SnazzyInfoWindow = lib.default
        })
    })
  },
  methods: {
    updateRouteInfo () {
      if (this.origin && this.destination) {
        this.$store.dispatch('rideHailing/getRouteInfo', [this.origin.latlng, this.destination.latlng])
      }
    },
    createInfoWindow (marker, content, onclick) {
      if (!SnazzyInfoWindow) return
      return marker.$markerPromise
        .then(() => {
          return new SnazzyInfoWindow({
            marker: marker.$markerObject,
            map: this.$refs.map,
            content: content,
            closeOnMapClick: false,
            placement: 'top',
            panOnOpen: false,
            callbacks: {
              afterOpen (v) {
                this._html.wrapper.addEventListener('click', onclick)
              }
            }
          })
        })
        .then(info => {
          info.open()
          return info
        })
    },
    setBounds () {
      if (!this.destination) {
        this.center = this.origin.latlng
      }
      if (this.origin && this.destination) {
        const bounds = new this.google.maps.LatLngBounds()
        bounds.extend(this.origin.latlng)
        bounds.extend(this.destination.latlng)
        const padding = window.innerWidth > 768
          ? { bottom: 300, right: 300, top: 100 }
          : { top: 0, bottom: 0 }
        this.$refs.map.fitBounds(bounds, padding)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.route-map
  height: calc(100vh - 64px);
  width: 100%;
  transition: height 0.3s;
  @media (max-width 768px)
    height 60vh

.marker {
  width: 50px;
  height: 50px;
}
</style>

<style lang="stylus">
@require '~snazzy-info-window/dist/snazzy-info-window.css'

.si-content-wrapper
  padding 0 !important
  border 0 !important
  box-shadow: 1px 1px 30px 0px rgba(0,0,0,0.3);
.si-close-button, .si-pointer-top, .si-pointer-left
  display none

.si-wrapper-top
  cursor pointer

.window-chevron
  font-size 25px
  vertical-align middle
  align-self center
.window-text
  display block
  padding 3px
  padding-left 10px
  line-height 2

.si-content-wrapper small
  text-transform uppercase
  padding: 9px;
  color white
  background: var(--q-color-primary, #218AE5);
</style>
