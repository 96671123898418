<template>
  <q-item multiline class="vehicle" @click.native="goToDetail">
    <q-item-section side class="img-container">
      <m-image
        :src="vehicle.vehicle.images[0]"
        :fallback="fallback"
        :style="'max-width: 64px; max-height: 64px'"
      />
    </q-item-section>
    <q-item-section>
      <q-item-label label lines="1">
        {{ vehicle.title }}
      </q-item-label>
      <q-item-label caption lines="2">
        {{ vehicle.description }} from {{ vehicle.provider }}
      </q-item-label>
    </q-item-section>
    <q-item-section side>
      <q-btn :label="priceText" outline class="btn-price" />
    </q-item-section>
  </q-item>
</template>

<script>
import { priceText as priceTextUtil } from 'utils/onwardTravel'

export default {
  props: ['vehicle'],
  data () {
    return {
      fallback: require('assets/blank-vehicle-hire.png')
    }
  },
  computed: {
    priceText () {
      return priceTextUtil(this.vehicle)
    }
  },
  methods: {
    goToDetail () {
      this.$store.dispatch('rideHailing/setValue', { prop: 'selectedVehicle', payload: this.vehicle })
      this.$router.push({ name: 'ondemand-ride-hailing-vehicle' })
    }
  }
}

</script>

<style lang="stylus" scoped>
.detail
  font-size 14px

.vehicle
  height 80px
  padding 10px 8px
  transition transform .2s ease-in
  cursor pointer

.img-container
  width 72px
  height: 64px
  display: flex;
  align-items: center;
  justify-content: center;

.btn-price
  color #1e88e5!important
  border-color #1e88e5!important

.vehicle:hover .btn-price
  background #1e88e5!important
  border-color #1e88e5!important
  color white!important
</style>
