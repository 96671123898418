<template>
  <div class="vehicle">
    <q-card class="bg-white">
      <q-card-section class="vehicle-card-title row">
        <div class="col-xs-12">
          <div class="vehicle-detail">
            <q-btn v-if="$q.platform.is.desktop" icon="fal fa-arrow-left" round flat dense @click="$router.go(-1)" />{{ selectedVehicle.title }}
            <span>{{ priceText }}</span>
          </div>
          <p class="description">
            {{ selectedVehicle.description }}
          </p>
        </div>
      </q-card-section>
      <q-card-section class="row">
        <div class="col-xs-4">
          <m-image :src="selectedVehicle.vehicle.images[0]" :fallback="fallback" class="img" />
        </div>
        <div class="vehicle-info col-xs-4">
          <q-icon class="icon" name="people" />
          <p v-if="selectedVehicle.vehicle.passenger_capacity">
            {{ $t('num.seats', { num: selectedVehicle.vehicle.passenger_capacity}) }}
          </p>
          <p v-else>
            {{ $t('abbreviation.not_applicable') }}
          </p>
        </div>
        <div class="vehicle-info col-xs-4">
          <q-icon class="icon" name="card_travel" />
          <p v-if="selectedVehicle.vehicle.luggage_capacity">
            {{ $tc('num.bags', { num: selectedVehicle.vehicle.luggage_capacity}) }}
          </p>
          <p v-else>
            {{ $t('abbreviation.not_applicable') }}
          </p>
        </div>
      </q-card-section>
    </q-card>

    <q-card class="bg-white">
      <q-card-section>
        <q-input
          v-model="info.phone_number"
          :error="$v.info.phone_number.$invalid"
          :label="$t('your_phone_number')"
          :rounded="$q.platform.is.mobile"
          :outlined="$q.platform.is.mobile"
          type="tel"
        />
        <q-input
          v-model="info.flight_number"
          :error="$v.info.flight_number.$invalid"
          error-message="Must be a valid IATO or ICAO flight number"
          :label="$t('flight.number')"
          :rounded="$q.platform.is.mobile"
          :outlined="$q.platform.is.mobile"
        />
        <q-input
          v-model="info.instructions"
          type="textarea"
          :label="$t('taxi.message_to_driver')"
          :rounded="$q.platform.is.mobile"
          :outlined="$q.platform.is.mobile"
        />
      </q-card-section>
    </q-card>

    <div class="action time">
      <div class="col-xs-12">
        <q-btn size="lg" class="btn-search" color="primary" :label="$t('confirm.vehicle')" @click="saveInfo" />
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { required, helpers } from 'vuelidate/lib/validators'
import { MImage } from 'components/'
import { priceText as priceTextUtil } from 'utils/onwardTravel'

const flightNumberValidator = (value) => {
  if (value.length < 3) return true
  return !helpers.req(value) || /^[A-Z0-9]{2}[0-9]{1,5}$/.test(value)
}

export default {
  components: { MImage },
  filters: {
    round (val) {
      return Math.round(val * 10) / 10
    }
  },
  data () {
    return {
      info: {
        instructions: '',
        phone_number: '',
        flight_number: ''
      },
      loading: false,
      fallback: require('assets/blank-vehicle-hire.png')
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      origin: 'rideHailing/origin',
      destination: 'rideHailing/destination',
      routeInfo: 'rideHailing/routeInfo',
      time: 'rideHailing/time',
      selectedVehicle: 'rideHailing/selectedVehicle'
    }),
    priceText () {
      return priceTextUtil(this.selectedVehicle)
    }
  },
  watch: {
    user: {
      immediate: true,
      handler () {
        this.info.phone_number = this.user.meta.phone_number
      }

    }
  },
  methods: {
    saveInfo () {
      this.$v.$touch()
      if (this.$v.$error) {
        if (this.$v.info.phone_number.$invalid) return this.$q.notify(this.$t('phone_number_required'))
        if (this.$v.info.flight_number.$invalid) return this.$q.notify(this.$t('error.invalid_flight_number'))
      }
      this.$store.dispatch('rideHailing/setValue', {
        prop: 'info',
        payload: _.pickBy(this.info)
      })
      this.$router.push({ name: 'ondemand-ride-hailing-summary' })
    }
  },
  validations: {
    info: {
      phone_number: { required },
      flight_number: { flightNumberValidator }
    }
  }
}
</script>

<style lang="stylus" scoped>
.vehicle
  background white
  position: absolute;
  height: calc(100vh - 64px);
  bottom: 0;
  width 100%

  @media (min-width 768px)
    height auto
    width 624px
    background transparent
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: 0

.vehicle-detail
  font-size 16px
  padding 0 0 12px 0
  line-height 1.1
  display flex
  justify-content space-between
  align-items center

.vehicle-info
  display flex
  justify-content center
  align-items center
  flex-direction column

.icon
  font-size 26px

.route
  display flex

.trip-info
  font-size 14px
  margin-bottom 8px

.line
  height calc(100% - 5px)
  width 2px
  border dashed 1px black
  margin-left 10px

.route-text
  margin-left 20px

.route-point
  position relative
  min-height 40px

.route-point:first-child span
  margin-bottom 10px

.route-point:last-child
  min-height auto

.dot
  width 15px
  height 15px
  border-radius 50%
  background black
  position absolute
  top 0px
  left -28px

.action
  padding 5px;
  position fixed;
  bottom 0
  width 100%
  left 0

  @media (min-width 768px) {
    position relative
  }

.btn-search
  width 100%
  transition width .3s

.btn-time
  width: 100%;

.description
  font-size 14px
  padding-bottom 12px

</style>

<style lang="stylus">
@keyframes fadeIn {
  0%   { opacity: 0 }
  100% { opacity: 1 }
}

.vehicle-card-title .q-card-section
  display flex
  justify-content space-between

.vehicle > *
  animation: fadeIn .3s forwards
</style>
